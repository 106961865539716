<script lang="jsx">
import ProductCardColorSet from './ProductCardColorSet.vue'
import ProductCardPropertyUpsell from './ProductCardPropertyUpsell.vue'
import SaleAttrTag from './SaleAttrTag.vue'

export default {
  name: 'ImgRightBottom',
  functional: true,
  inject: {
    item: {
      from: 'item',
      default: () => ({}),
    },
    config: {
      from: 'config',
      default: () => ({}),
    },
  },
  props: {
    relatedColor: {
      type: Array,
      default: () => [],
    },
    getCornerPropertyUpsell: {
      type: Object,
      default: null
    },
    beltLabel: {
      type: [Object, Boolean],
      default: false,
    },
  },
  render(h, { props = {}, injections = {}, listeners }) {
    const { config = {}, item = {} } = injections
    const { showMultiColor, showPropertyUpsell, showCategoryViewStyle = false } = config
    const { relatedColor = [], getCornerPropertyUpsell, beltLabel } = props

    const hasRelatedColor = relatedColor.length > 1
    const saleAttrText = item.pretreatInfo?.saleAttr?.label || ''
    const saleAttrTagVisible = config.showSaleAttr && saleAttrText

    if (hasRelatedColor) {
      listeners?.['showColorSet']?.({
        color: relatedColor.map(item => item.goods_id).join('`'),
      })
    }

    const hasShapedBelt = ['countdownAbShaped', 'discountAbShaped', 'willSaveAbShaped'].includes(beltLabel?.component)

    // 属性类卖点: 属性优先
    if (showPropertyUpsell && getCornerPropertyUpsell && getCornerPropertyUpsell.isShowColorBlock === 'noShow') {
      return (
        <ProductCardPropertyUpsell getCornerPropertyUpsell={getCornerPropertyUpsell} hasShapedBelt={hasShapedBelt} />
      )
    }

    // 属性类卖点: 色块优先
    if (showPropertyUpsell && getCornerPropertyUpsell && getCornerPropertyUpsell.isShowColorBlock === 'show') {
      if (showMultiColor && hasRelatedColor) {
        return (
          <div class="product-card__color-set-wrapper">
            <ProductCardColorSet direction={'vertical'} relatedColor={relatedColor} />
          </div>
        )
      } else {
        return (
          <ProductCardPropertyUpsell getCornerPropertyUpsell={getCornerPropertyUpsell} hasShapedBelt={hasShapedBelt} />
        )
      }
    }

    // 未命中属性类卖点，则色块逻辑正常显示
    if (showMultiColor && hasRelatedColor) {
      return (
        <div class="product-card__color-set-wrapper">
          <ProductCardColorSet direction={'vertical'} relatedColor={relatedColor} />
        </div>
      )
    }

    // 只有收藏使用的属性标签, 品类视图下销售属性不展示
    if (!showMultiColor && saleAttrTagVisible && !showCategoryViewStyle) {
      return (
        <SaleAttrTag label={saleAttrText} />
      )
    }

    return null
  },
}
</script>

<style lang="less">
.product-card__color-set-wrapper {
  isolation: isolate;
  .font-dpr(24px);
  display: flex;
  flex-direction: column;
  align-items: self-end;
}
</style>
