
<script lang="jsx">
import ProductCardServiceTag from './ProductCardServiceTag.vue'
import SearchFilterLabel from './SearchFilterLabel.vue'

export default {
  name: 'ProductCardLabelsContainer',
  functional: true,
  components: { ProductCardServiceTag, SearchFilterLabel },
  inject: ['item', 'config'],
  render(h, { injections, listeners, slots }) {
    const { pretreatInfo } = injections?.item ?? {}
    const { hideServiceLabels, showSearchFilters } = injections?.config ?? {}
    const hasSearchFilters = showSearchFilters && pretreatInfo?.searchFilters?.length > 0

    const handleClickSearchFilter = (e) => {
      listeners?.['clickSearchFilter']?.(e)
    }

    return (
      <div class="product-card__label-container">
        { !hideServiceLabels ? 
          <ProductCardServiceTag>
            { slots().goodsServiceTag }
          </ProductCardServiceTag> 
          : null 
        }
        { hasSearchFilters ? <SearchFilterLabel vOn:clickSearchFilter={ handleClickSearchFilter } /> : null }
      </div>
    )
  }
}

</script>
