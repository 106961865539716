<script lang="jsx">
export default {
  name: 'ProductItemGoodsTitleLabel',
  functional: true,
  render(h, { props }) {
    const { goodsNameLabel } = props
    const styles = {
      backgroundColor: goodsNameLabel?.backgroundColor,
      color: goodsNameLabel?.fontColor,
    }

    return (
      goodsNameLabel.labelLang 
        ? (
          <span 
            class="product-card__goods-label-content"
            style={styles}
          >
            { goodsNameLabel.labelLang }
          </span>
        ) : null
    )
  }
}
</script>


<style lang="less">
.product-card__goods-label-content {
  margin-right: 0.0533rem;
  padding: 0 .08rem;
  height: .373rem;
  line-height: .373rem;
  vertical-align: baseline;

  border-radius: .0533rem;

  background: var(--sui-color-new, #3CBD45);

  font-size: .267rem;
  font-weight: 500;
  color: #EDFFEE;

  unicode-bidi: plaintext; // ar 站 rtl 只针对 ar 语言，一旦 英文与阿拉伯文混排，new 标签会加入英文的方向，导致显示问题
}
</style>
