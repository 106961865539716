<script lang="jsx">
import { Loading as SLoading } from '@shein/sui-mobile'
import { sui_icon_add_12px } from '@shein-aidc/icon-vue2'
import CheckoutNum from '../UI/CheckoutNum'

export default {
  name: 'ProductCardAddBagBtn',
  functional: true,
  components: { sui_icon_add_12px, SLoading },
  props: {
    // 控制loading
    loading: {
      type: Boolean,
      default: false,
    },
    checkoutNum: {
      type: Number,
      default: 0,
    },
    showCheckout: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['config', 'item', 'language'],
  render(h, { props, injections, listeners, data }) {
    const language = injections.language
    const addBagBtnStyle = injections.config?.style?.addBagBtnStyle ?? 'default'

    const loadingMode = injections.config?.style?.loadingMode ?? 'light'

    // 按钮的不同样式
    const addBagBtnStyleUIMap = new Map([
      ['default', () => {
        return (
          <i
            class={[
              'sh_m_sui_icon_addtocart_20px',
              'product-card__add-bag',
              'j-icon-addtobag',
            ]}
          />
        )
      }],
      ['AddText', () => {
        const text = language?.SHEIN_KEY_PWA_29778 || 'Add'
        
        // 字符超过直接icon代替
        if (text?.length > 4) {
          return (
            <sui_icon_add_12px 
              class="icon-add"
              size="0.373rem"
            />
          )
        } else {
          return (
            <span class="add-btn-text">{text}</span>
          )
        }
      }],
      [
        'checkout', () => {
          const text = language?.SHEIN_KEY_PWA_31870 || 'CHECKOUT'
          return (
            <span class="add-btn-text add-btn-checkout">{text}</span>
          )
        }
      ]
    ]) 

    const loading = props.loading
    const checkoutNum = props.checkoutNum
    const showCheckout = props.showCheckout

    // SHEIN_KEY_PWA_29778
    const addBagBtnStyleUI = (showCheckout) => {
      if (loading) {
        return (
          <s-loading
            show
            size="small"
            mode={loadingMode}
            cur-page-scroll-fix={false}
          />
        )
      } else {

        if (showCheckout) {
          return addBagBtnStyleUIMap.get('checkout')()
        }

        return addBagBtnStyleUIMap.get(addBagBtnStyle)()
      }
    }

    const handleClickFun = showCheckout ? listeners['clickCheckout'] : listeners['openQuickAdd']

    return (
      <button
        vOn:click_stop={handleClickFun}
        class={[
          'product-card__add-btn',
          data.class,
          data.staticClass,
        ]}
      >
        {
          addBagBtnStyleUI(showCheckout)
        }

        <CheckoutNum num={checkoutNum} />
      </button>
    )
  }
}
</script>

<style lang="less">
.product-card {
  &__add-btn {
    position: relative;
    margin: 0.0533rem 0 0.0533rem .106rem;
    display: flex;
    width: 36px;
    height: 24px;
    color: #000;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: var(--product-card____add-btn-border-color, #000);
    background-color: var(--product-card____add-btn-bg, #fff);

    border-radius: 5000px;

    .add-btn-text {
      line-height: .32rem;
      font-weight: 500;
      font-size: .32rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .add-btn-checkout {
      font-size: 11px;
    }

    .icon-add, .add-btn-text, .product-card__add-bag {
      color: var(--product-card____add-btn-color, #000);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .sh_m_sui_icon_addtocart_20px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiAzLjI1VjQuNzVINC4xNDA1Nkw1LjM0MDU2IDE0LjA1SDE3LjA0MjdMMTguMDA0MiA2LjczOTZIMTYuNDkxM0wxNS43MjcxIDEyLjU1SDYuNjU5NDRMNS40NTk0NCAzLjI1SDJaIiBmaWxsPSIjMUMxQzFDIj4KPC9wYXRoPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjkgNC43NDcxOUgxMC40VjYuNzM2NjlIOC40MDAwMlY4LjIzNjY5SDEwLjRWMTAuMjI2NEgxMS45VjguMjM2NjlIMTMuOVY2LjczNjY5SDExLjlWNC43NDcxOVoiIGZpbGw9IiMxQzFDMUMiPgo8L3BhdGg+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4zMjk5NiAxNi4zQzcuMzI5OTYgMTUuNzQ3OCA2Ljg4MjI0IDE1LjMgNi4zMjk5NiAxNS4zQzUuNzc3NjcgMTUuMyA1LjMyOTk2IDE1Ljc0NzggNS4zMjk5NiAxNi4zQzUuMzI5OTYgMTYuODUyMyA1Ljc3NzY3IDE3LjMgNi4zMjk5NiAxNy4zQzYuODgyMjQgMTcuMyA3LjMyOTk2IDE2Ljg1MjMgNy4zMjk5NiAxNi4zWiIgZmlsbD0iIzFDMUMxQyI+CjwvcGF0aD4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4wNSAxNi4zQzE3LjA1IDE1Ljc0NzggMTYuNjAyMyAxNS4zIDE2LjA1IDE1LjNDMTUuNDk3OCAxNS4zIDE1LjA1IDE1Ljc0NzggMTUuMDUgMTYuM0MxNS4wNSAxNi44NTIzIDE1LjQ5NzggMTcuMyAxNi4wNSAxNy4zQzE2LjYwMjMgMTcuMyAxNy4wNSAxNi44NTIzIDE3LjA1IDE2LjNaIiBmaWxsPSIjMUMxQzFDIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
    }
  }

  // 体验优化:增加移动端点击触发的范围
  &__add-btn::after {
    --tap-increment: -6px;
    content: '';
    position: absolute;
    top: var(--tap-increment);
    left: var(--tap-increment);
    right: var(--tap-increment);
    bottom: var(--tap-increment);
  }
}
</style>
