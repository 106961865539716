
<script lang="jsx">
import { getQueryString, stringifyQueryString } from '@shein/common-function'
import { getRankProductsUrl } from 'public/src/pages/common/ranking_list/rankTool'

const { langPath } = gbCommonInfo
// <!-- 榜单组件 -->
export default {
  name: 'ProductCardSellingPropositionRank',
  functional: true,
  components: {  },
  inject: {
    config: {
      default: () => ({})
    },
  },
  render(h, { props, data, injections }) {
    const { disableApartFromTheDetailsJump } = injections?.config || {}
    const { bffGoodsInfo, goods_id } = props?.item || {}

    // eslint-disable-next-line no-unused-vars
    const { icon, score, rankTypeText, composeIdText, color = '', backgroundColor = '' } = props?.rankingList ?? {}

    const rankTitleText = bffGoodsInfo ? rankTypeText : `#${score} ${rankTypeText}`

    const rankingLink = async () => {
      if (typeof window === 'undefined') return ''
      let ici = getQueryString({ key: 'ici' }) || ''
      let { contentCarrierId, itemInfoId, carrierTempId, title, activityName, rankingType, carrierSubType } = props?.rankingList ?? {}
      let params = {
        page_from: 'detail',
        contentCarrierId,
        item_id: itemInfoId,
        page_title: title,
        rankTypeText: encodeURIComponent(title),
        templateId: carrierTempId,
        currentItem: goods_id,
        ici: encodeURIComponent(ici),
        scene_id: 52,
        src_module: 'ranking_list_label',
        src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${ props?.index + 1}\`jc=${contentCarrierId}`,
        src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
        goods_id,
      }
      const rankProductsUrl = await getRankProductsUrl({
        carrierSubType,
        langPath,
        params,
        from: 'listLabel',
      })
      if (rankProductsUrl) {
        return {
          isNewPage: false,
          link: rankProductsUrl,
        }
      }
      const actUrl = `${langPath}/campaign/${activityName}?${stringifyQueryString({
        queryObj: params,
      })}`
      return {
        isNewPage: true,
        link: actUrl,
      }
    }

    const bindData = (() => {
      const { contentCarrierId, rankingType, carrierSubType } = props?.rankingList ?? {}
      return {
        src_module: 'ranking_list_label',
        src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${props?.index + 1}\`jc=${contentCarrierId}`,
        goods_id: goods_id ?? '',
      }
    })()

    const handleClickRankingList = async () => {
      if (disableApartFromTheDetailsJump) return false
      
      const {
        link,
        isNewPage,
      } = await rankingLink()
      if (link) {
        let { contentCarrierId, rankingType, carrierSubType } = props?.rankingList ?? {}
        daEventCenter.triggerNotice({
          daId: '2-3-38',
          bindData: {
            src_module: 'ranking_list_label',
            src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${props?.index + 1}\`jc=${contentCarrierId}`,
            goods_id: goods_id ?? '',
          }
        })
        if (isNewPage || !props.router) {
          location.href = link
        } else {
          props.router.push(link)
        }
      }
    }
    // TODO: 后续需要接入榜单背景颜色 字体颜色取CCC数据
    const containerStyle = {
      // color
    }
    const mainStyle = {
      // backgroundColor
    }
    const { PUBLIC_CDN = '', GB_cssRight  } = props?.constantData || {}
    const rankSplitImgSrc = `${PUBLIC_CDN}/pwa_dist/images/sui_pic_List@2x-46815e7bc6.png`
    const rankLink = (
      <div 
        class="product-card__selling-proposition-rank product-card__selling-rank-link"
        style={[containerStyle, data.style, data.staticStyle]}
        vOn:click_stop={handleClickRankingList}
        v-expose={{ id: '2-3-40', data: bindData }}
      >
        <img class="rank-logo" src={icon} />
        <div 
          class="rank-main"
          style={mainStyle}
        >
          <span class="rank-title">{rankTitleText}</span>
        </div>
        <span class="rank-sub">
          <img 
            class={{
              'rank-split-img': true,
              'rank-split-img_ar': GB_cssRight,
            }}
            src={rankSplitImgSrc} 
          />
          <span class="rank-sub-text">{composeIdText}</span>
          <i class="suiiconfont sui_icon_more_right_12px"></i>
        </span>
      </div>
    )
    const rankText = (
      <div 
        class="product-card__selling-proposition-rank product-card__selling-rank-link product-card__selling-rank-text"
        style={[containerStyle, data.style, data.staticStyle]}
        v-expose={{ id: '2-3-40', data: bindData }}
      >
        <img class="rank-logo" src={icon} />
        <div 
          class="rank-main"
          style={mainStyle}
        >
          <span class="rank-title">{rankTitleText}</span>
        </div>
        <span class="rank-sub">
          <img 
            class={{
              'rank-split-img': true,
              'rank-split-img_ar': GB_cssRight,
            }}
            src={rankSplitImgSrc} 
          />
          <span class="rank-sub-text">{composeIdText}</span>
        </span>
      </div>
    )
    return props.rankingLabelClickable ? rankLink : rankText
  }
}
</script>

<style lang="less">
.product-card__selling-proposition-rank {
  .flexbox();
  .align-center();
  flex-wrap: nowrap;

  height: var(--selling-rank-height, 0.373rem);
  
  font-size: .293rem;

  & > span, .rank-main > * {
    margin-left: .106rem;
    line-height: 1em;
  }

  .rank-main {
    flex-wrap: nowrap;
    .flexbox();
    .align-center();
    background: linear-gradient(90deg, #FFEDDC 25.19%, rgba(255, 249, 220, 0.20) 100%);
  }


  .rank-logo {
    width: .32rem;
  }

  .rank-title {
    padding-top: 0; // 修复榜单页面找相似弹窗样式覆盖
    white-space: nowrap;
    font-size: .293rem;
    color: #CC6E00;
  }
  

  .rank-sub {
    white-space: nowrap;
    color: var(--sui-color-gray-dark-3, #767676);
  }

  span:last-child {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.product-card__selling-rank-link {
  margin-top: var(--selling-rank-margin-top, 0.08rem);
  .rank-main {
    position: relative;
    padding-left: 0.32rem;
    border-radius: .1867rem 0 0 .1867rem;
    background: linear-gradient(269deg, #FFE79F 5.63%, #FFEBAC 55.21%);
  }
  .rank-logo {
    position: absolute;
    z-index: @zindex-hack;
    height: .373rem;
  }
  .rank-split-img {
    position: relative;
    z-index: @zindex-hack;
    height: .35rem;
    &_ar {
      transform: rotate(180deg);
    }
  }
  .rank-title {
    line-height: .35rem;
  }
  .rank-sub {
    position: relative;
    display: flex;
    margin-left: 0;
    background: #fef7e2;
    height: .35rem;
    line-height: .35rem;
    padding-right: .32rem;
    color: rgba(204, 110, 0, 0.68);
  }
  .rank-sub-text {
    margin-left: 0.064rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sui_icon_more_right_12px {
    position: absolute;
    right: 0;
    color:rgba(204, 110, 0, 0.6);
  }
}
.product-card__selling-rank-text {
  .rank-sub {
    padding-right: 0;
  }
}
</style>
