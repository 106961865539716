var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-card__feed"},[_c('div',{staticClass:"product-card__feed-close",class:{oneline: _vm.isOneLine}},[_c('i',{staticClass:"suiiconfont sui_icon_nav_close_24px",on:{"click":function($event){$event.stopPropagation();return _vm.close(false)}}})]),_vm._v(" "),(_vm.list.length > 0)?_c('div',{ref:"feedList",class:{
      'product-card__feed-list': !_vm.isOneLine,
      'product-card__feed-onelist': _vm.isOneLine,
      'show': _vm.showText,
      'scroll': (_vm.list.length > 3 && _vm.similarShow) || _vm.list.length == 5,
      'similar': _vm.similarShow && !_vm.more && !_vm.isSquareImg,
      'onecolumn': _vm.list.length < 3,
      'onescroll': _vm.list.length >= 5,
      'square': _vm.isSquareImg && _vm.more,
      'squareScroll': _vm.isSquareImg && !_vm.more,
    },style:({'overflow-y': _vm.more && !_vm.isOneLine ? 'hidden' : 'scroll'})},[_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,ref:"feedLi",refInFor:true,staticClass:"product-card__feed-li",on:{"click":function($event){$event.stopPropagation();return _vm.clickFeedLi(index, item.id)}}},[_c('span',{class:{small: _vm.feedSpan[index]}},[_vm._v(_vm._s(item.negFeedBackName))])])}),_vm._v(" "),(_vm.more && !_vm.isOneLine)?_c('div',{staticClass:"product-card__feed-grey"}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.more && !_vm.isOneLine),expression:"more && !isOneLine"}],staticClass:"product-card__feed-more"},[_c('i',{staticClass:"iconfont icon-forward-down20px",on:{"click":function($event){$event.stopPropagation();return _vm.showMore.apply(null, arguments)}}})]),_vm._v(" "),(_vm.similarShow)?_c('div',{staticClass:"product-card__feed-similar",class:{more: _vm.more && !_vm.isOneLine, onlysm: _vm.list.length == 0, oneline: _vm.isOneLine},on:{"click":function($event){$event.stopPropagation();return _vm.openSimilar.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__feed-similar-btn",class:{full: _vm.list.length == 0 && !_vm.isOneLine, uplowStructure: _vm.uplowStructure, oneline: _vm.isOneLine}},[_c('i',{staticClass:"suiiconfont sui_icon_findsimilar_18px"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_16789))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }