<script lang="jsx">
import ProductItemOverlay from './UI/ProductItemOverlay'
import ProductItemOperationBtns from './ProductItemOperationBtns'

export default {
  name: 'ItemSoldout',
  functional: true,
  props: {
    curData: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: -1
    },
    showSelect: Boolean
  },
  render(h, { props, listeners }) {
    const isRW = props.constantData.IS_RW
    const { showCategoryViewStyle = false } = props.config

    return (
      <ProductItemOverlay
        value={props.isSoldOut}
        close={false}
        vOn:click={props.showSelect ? listeners['select'] : listeners['clickItem']}
      >
        <div 
          class={[
            'product-card__soldout-content',
            {
              'categoryview-soldout': showCategoryViewStyle
            }
          ]}
        >
          {
            (
              <i class={[
                'iconfont',
                {
                  'icon-category-': isRW,
                  'icon-SOLDOUT': !isRW,
                },
              ]}></i>
            )
          }
          <p>{ props.language.SHEIN_KEY_PWA_14987 }</p>
          {/* 操作按钮组合 */}
          {
            showCategoryViewStyle ? null : 
              props.showSelect ? null : (
                <ProductItemOperationBtns
                  cur-data={props.curData}
                  language={props.language}
                  config={props.config}
                  index={props.index}
                  is-sold-out={props.isSoldOut}
                  show-similar-popup={props.showSimilarPopup}
                  type="normalFlow"
                  vOn:clickAddToBoard={listeners['clickAddToBoard']}
                  vOn:clickDelete={listeners['clickDelete']}
                />
              )
          }
        </div>
      </ProductItemOverlay>
    )
  },
}
</script>

<style lang="less">
.product-card {
  &__soldout-content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    z-index: @zindex-hack;

    width: 100%;
    color: #fff;
    text-align: center;
    [class*="iconfont"] {
      .font-dpr(72px);
    }
    p {
      .font-dpr(28px);
      font-weight: bold;
    }
  }
  &__soldout-content.categoryview-soldout {
    line-height: 1;
    [class*="iconfont"] {
      .font-dpr(64px);
    }
    p {
      .font-dpr(24px);
      margin: 0.2rem 0;
    }
  }
}
</style>
