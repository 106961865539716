/**
 * @file 商品上的角标控制器
 */

import ItemLocateLabels from './ItemLocateLabels.vue'
import ProductCardImgLeftTop from './ProductCardImgLeftTop.vue'
import ProductCardImgRightTop from './ProductCardImgRightTop.vue'
import { getLocateLabelsAna } from '../../analysis/locateLabelsAna'
import { isActivityPage } from '../../js/utils'

/**
 * 
 * @param {Function} h 不能删除，vue编译时会用到
 * @param {*} param1 
 * @returns 
 */
export const controlItemLabels = (h, {
  locateLabels,
  multiColumn,
  imgDesignWidth,
  isFlashSale,
  config,
  pretreatInfo,
  // language,
  constantData,
  curData,
  finalPriceAndDiscountInfo,
  changeMarkFocus,
  promoLabelSrc,
  selectStatus,
  showSelect,
  selectPosition,
  // showHotTag,
  $scopedSlots
}) => {
  // 专题页、闪购页需展示品牌、系列、大促角标的旧逻辑
  const needShowOldLabel = isActivityPage() || isFlashSale
  const locateLabelSet = new Set(Object.keys(locateLabels || {}).filter(key => Object.keys(locateLabels[key] || {}).length))

  // 左上角折扣角标
  const finalDiscount = finalPriceAndDiscountInfo?.finalDiscount || {}
  const priceStateCollection = finalPriceAndDiscountInfo?.priceStateCollection || {}
  const showDiscountLabel = config.showDiscountInMainImg && finalDiscount?.show && !priceStateCollection.hideDiscountLabel

  // @tips 收藏列表商品项有个checkbox
  const topRightProps = locateLabelSet.has('UPPER_RIGHT_LABEL') ? {
    promoLabelSrc: '',
  } : {
    promoLabelSrc,
  }

  const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet)

  return {
    locateLabelsAna,
    ItemLocateLabels: () => locateLabelSet.size ? <ItemLocateLabels
      scopedSlots={{
        UPPER_LEFT_LABEL: (scope) => $scopedSlots.UPPER_LEFT_LABEL?.(scope),
        UPPER_RIGHT_LABEL: (scope) => $scopedSlots.UPPER_RIGHT_LABEL?.(scope),
        LOWER_LEFT_LABEL: (scope) => $scopedSlots.LOWER_LEFT_LABEL?.(scope),
        LOWER_RIGHT_LABEL: (scope) => $scopedSlots.LOWER_RIGHT_LABEL?.(scope),
      }}
      locate-labels={locateLabels}
      multi-column={multiColumn}
      lazy-img={constantData.LAZY_IMG_PIXEL}
      img-design-width={imgDesignWidth} /> : null,
    
    ProductCardImgLeftTop: () => <ProductCardImgLeftTop
      select-status={selectStatus}
      show-select={showSelect}
      select-position={selectPosition}
      show-discount-label={showDiscountLabel}
    />,

    ProductCardImgLeftBottom: () => null, 
    
    ProductCardImgRightTop: () => <ProductCardImgRightTop
      pretreat-info={pretreatInfo}
      cur-data={curData}
      config={config}
      constant-data={constantData}
      select-status={selectStatus}
      show-select={showSelect}
      select-position={selectPosition}
      img-design-width={imgDesignWidth}
      need-show-old-label={needShowOldLabel}
      {...{ props: topRightProps }}
      vOn:onFocus={() => changeMarkFocus(true)}
      vOn:onBlur={() => changeMarkFocus(false)}
    />
  }
}
