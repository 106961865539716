<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="renderPopover"
      v-model="popoverActive"
      :content="pricePopoverLang"
      :placemen="popoverPlacemen"
      trigger-type="user"
      show-close-icon
      
      :prop-style="{
        maxWidth: '6.4rem',
        zIndex: '999999',
        transform: 'translate3d(0px, 0px, 999999px)'
      }"
    >
      <div 
        slot="reference"
        class="suggested-popover" 
        @click.stop="popoverClick"
        @touchstart.stop="popoverClickTouchstart"
      >
        <SuggestedSalePrice
          :value="priceValue"
          :label-text="lowestPriceLabelLang"
        />
        <i 
          ref="iconCaution"
          class="icon-suggested-caution sh_m_sui_icon_caution_12px_2"
          :data-id="id"
          :style="{
            opacity: showIconSuggested ? 1 : 0
          }"
        ></i>
      </div>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
      :label-text="lowestPriceLabelLang"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { Popover as SPopover } from '@shein/sui-mobile'

import { useCardPopover } from '../../js/hooks/useCardPopover.js'

import { toRefs } from 'vue'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  priceData: {
    type: Object,
    default: () => {},
  }
})

const { goodsInfo, priceData } = toRefs(props)

const lowestPriceLabelLang = priceData.value?.labelText

const renderPopover = priceData.value?.showRetailPricePopover
const pricePopoverLang = priceData.value?.popoverLang ?? ''

// 德国展示原价  法国挪威展示合规价
const priceValue = priceData.value?.priceData

const {
  id,
  popoverActive,
  popoverPlacemen,
  iconCaution,
  showIconSuggested,
  popoverClick,
  popoverClickTouchstart
} = renderPopover ? useCardPopover({
  needDomCalculation: true,
  goodsInfo: goodsInfo.value
}) : {}

</script>

<style lang="less" scoped>
.suggested-popover {
  display: flex;
  align-items: center;
}
.icon-suggested-caution {
  position: relative;
  top: -1px;

  margin-left: 1px;
  width: .32rem;
  height: .32rem;

  opacity: 0;
}


.sh_m_sui_icon_caution_12px_2 {
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwLjVDOS4wMzc1NyAwLjUgMTEuNSAyLjk2MjQzIDExLjUgNkMxMS41IDkuMDM3NTcgOS4wMzc1NyAxMS41IDYgMTEuNUMyLjk2MjQzIDExLjUgMC41IDkuMDM3NTcgMC41IDZDMC41IDIuOTYyNDMgMi45NjI0MyAwLjUgNiAwLjVaTTYgMS41QzMuNTE0NzIgMS41IDEuNSAzLjUxNDcyIDEuNSA2QzEuNSA4LjQ4NTI4IDMuNTE0NzIgMTAuNSA2IDEwLjVDOC40ODUyOCAxMC41IDEwLjUgOC40ODUyOCAxMC41IDZDMTAuNSAzLjUxNDcyIDguNDg1MjggMS41IDYgMS41Wk02LjUgOFY5SDUuNVY4SDYuNVpNNi41IDNWN0g1LjVWM0g2LjVaIiBmaWxsPSIjNzY3Njc2Ij4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
  background-size: 100%;
  width: .32rem;
  height: .32rem;
}
</style>
