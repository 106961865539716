<script lang="jsx">
import ImgBottomLabel from '../UI/ImgBottomLabel.vue'
import SaveOfPriceCut from './SaveOfPriceCut.vue'
const ImgBottomBeltLabel = () => import('./ImgBottomBeltLabel.vue')

export default {
  name: 'ProductItemImgBottom',
  functional: true,
  inject: ['item', 'config', 'constantData', 'language'],
  props: {
    comingSoon: {
      type: Boolean,
      default: false  
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    showBestDeal: {
      type: Boolean,
      default: false
    },
    beltLabel: {
      type: [Object, Boolean],
      // false不会渲染组件
      default: false,
    },
    followLabelVisible: {
      type: Boolean,
      default: false,
    },
    saveAmount: {
      type: String,
      default: ''
    },
  },
  render(h, { props, slots, injections }) {
    const { 
      beltLabel,
      followLabelVisible,
      isSoldOut,
      showBestDeal,
      comingSoon,
      saveAmount
    } = props || {}

    const { 
      item,
      config,
      constantData,
      language
    } = injections || {}

    const {
      bffGoodsInfo,
      pretreatInfo
    } = item || {}

    const { showLeftStock, sa, showSaveOfPriceCut, showCategoryViewStyle = false } = config || {}
    const { activity_from } = sa || {}
    const { stockLeft } = pretreatInfo || {}
    const { IS_RW } = constantData || {}
    
    // 仅shein
    const showStockLeft = stockLeft && showLeftStock && (IS_RW ? activity_from !== 'wishlist' : true)

    const labelInfo = (() => {
      // 保留款,跟价款腰带
      const beltInfo = {
        type: ['orange', '12PX'],
        icon: 'sui_icon_best_deal_16px',
        text: showBestDeal ? language.SHEIN_KEY_PWA_24438 : language.SHEIN_KEY_PWA_26113,
        lineClamp: showBestDeal ? 2 : 1,
        center: true,
      }
      let stockLeftInfo = {
        type: ['black', '12PX'],
        text: stockLeft,
        center: true,
      }
      if (showCategoryViewStyle) {
        stockLeftInfo = {
          type: ['categoryOrangeColor', '11PX'],
          text: stockLeft,
          center: true,
        }
      }
      if (showBestDeal) {
        return [beltInfo]
      }
      if (followLabelVisible && showStockLeft) {
        return [stockLeftInfo, beltInfo]
      }
      if (followLabelVisible) {
        return [beltInfo]
      }
      if (showStockLeft) { // 剩余库存 例子：only xx left
        return [stockLeftInfo]
      } else if (isSoldOut && comingSoon) { // coming soon
        return [{
          type: ['black', '12PX'],
          text: language.SHEIN_KEY_PWA_15059,
          center: true
        }]
      }

      return []
    })()

    return (
      <div class="product-card__img-bottom">
        {/* 图片底部slot */}
        {/* 例如: 实时推荐反馈 */}
        {slots().imgBottom}

        {/* 保留款 > 跟价款，剩余库存(only xx left) > coming soon */}
        {
          labelInfo.map(infoItem => (<ImgBottomLabel
            type={infoItem.type}
            center={infoItem.center}
            label-text={infoItem.text}
            label-icon={infoItem.icon}
            line-clamp={infoItem.lineClamp}
          />))
        }
        {/* 腰带 */}
        {
          beltLabel
            ? (
              <ImgBottomBeltLabel
                bff-goods-info={bffGoodsInfo}
                belt-label={beltLabel}
                pretreat-info={pretreatInfo}
              />
            )
            : null
        }
        { 
          showSaveOfPriceCut && saveAmount ? 
            <SaveOfPriceCut 
              save-amount={saveAmount} 
            />
            : null 
        }

        {/* 例如: This Item */}
        {/* 图片底部slot(盖在腰带之上) */}
        {slots().imgBottomInTop}
      </div>
    )
  },
}
</script>

<style lang="less">
.product-card {
  &__img-bottom {
    .font-dpr(24px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: self-end;
  }
}
</style>
