<template>
  <div
    v-if="showSearchFilterWords"
    class="product-card__search-filter"
    :class="{'transparent': !initialized}"
  >
    <template v-for="(item, idx) in searchFilters">
      <div
        :key="item.filterId"
        :class="['search-filter__label', `searchFilter${idx}`]"
        @click.stop="clickSearchFilter({ item, idx })"
      >
        <span class="search-filter__label-text">{{ item.filterWord }}</span>
        <i class="suiiconfont sui_icon_search_16px"></i>
      </div>
    </template>
  </div>
</template>

<script>
// 分布式筛选组件
import { mapState } from 'vuex'
import { clickSearchFilterAna } from '../../analysis'
import { formatSearchItem, handleSearchFilterQuery } from 'public/src/pages/product_list_v2/js/search.common'
import { checkSlicingDomList } from '../../js/utils.js'

export default {
  name: 'BottomLabelsSearchFilter',
  inject: ['item', 'index', 'setAttrForAnalysis'],
  data() {
    return {
      initialized: false,

      searchFilters: []
    }
  },
  computed: {
    ...mapState(['searchFilterWords']),
    // TODO: 等融合推全了，可以下掉这里的逻辑，不展示的逻辑放bff处理了。
    showSearchFilterWords () {
      return this.searchFilterWords?.length < 4 && this.searchFilters?.length
    }
  },
  created() {
    this.initComponentsData()
  },
  mounted() {
    if (this.searchFilters.length > 0 && this.showSearchFilterWords) {
      this.checkSlicingTagList()
      this.$nextTick(() => {
        this.initialized = true
      })
    } else {
      this.initialized = true
    }
  },
  methods: {
    initComponentsData() {
      const { pretreatInfo } = this.item ?? {}

      const { 
        searchFilters
      } = pretreatInfo ?? {}

      this.searchFilters = [...searchFilters]
    },
    /**
     * 计算宽度是否能在一行展示，不够则截断
     */
    checkSlicingTagList() {
      const { width } = this.$el.getBoundingClientRect()
      const tagListDom = this.$el.querySelectorAll('.search-filter__label')
      const { showIndexList } = checkSlicingDomList(width, tagListDom)
      
      if (showIndexList.length) {
        this.searchFilters = this.searchFilters.filter((item, index) => showIndexList.includes(index))

        const params = this.searchFilters.map((item, index) => {
          let { id, dataSource } = item
          return [this.index + 1, index + 1, dataSource, id].join('`')
        })

        this.setAttrForAnalysis((el) => {
          el.setAttribute('data-search-filter', params.join(','))
        })
      }
    },

    async clickSearchFilter ({ item, idx }) {

      formatSearchItem('SEARCH_FILTER_LABEL', item)
      if (item.filterId && item.filterWord) {
        // 埋点
        this.clickAna({ item, idx })

        const query = handleSearchFilterQuery({ 
          searchItem: item,
          searchFilterWords: this.searchFilterWords,
          query: this.$route.query
        })
        this.$emit('clickSearchFilter', query)
      }
    },
    clickAna ({ item, idx }) {
      let filterIndex = idx

      let { id, dataSource } = item
      clickSearchFilterAna({ id, itemIndex: this.index + 1, filterIndex: filterIndex + 1, dataSource })
    }
  }
}
</script>

<style lang="less">
.product-card__search-filter {
  .flexbox();
  .align-center();
  margin-top: .106rem;

  flex-wrap: nowrap;

  overflow: hidden;

  &.transparent {
    opacity: 0;
  }

  .search-filter {
    &__label {
      flex: 0 0 auto;

      .flexbox();
      .align-center();
      max-width: 100%;
      height: 16px;
      line-height: 16px;
      padding: 0 0.1067rem;
      margin-top: 0.1067rem;
      color: @sui_color_gray_dark2;
      background: @sui_color_gray_weak2;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
      .suiiconfont.sui_icon_search_16px { /* stylelint-disable-line */
        display: inline-block;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        margin-left: 0.0533rem;
      }
    }
    &__label-text {
      .text-overflow();
      // 4.52rem -> 2图宽度，12px -> 图标宽度，0.2134rem -> 左右padding，0.0533rem -> 图标左边距
      max-width: calc(4.52rem - 12px - 0.2134rem - 0.0533rem);
    }
  }
}
</style>
