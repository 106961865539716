<script lang="jsx">
import { transformImg } from '@shein/common-function'

export default {
  name: 'ProductCardColorItem',
  functional: true,
  props: {
    colorImage: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  render(h, { props, data }) {
    return (
      <div
        class={[
          'product-card__color-item',
          data.class,
          data.staticClass,
          {
            'lazyload': !!props.colorImage,
          },
        ]}
        style={[
          data.style,
          data.staticStyle,
          {
            zIndex: 3 - props.index,
          },
        ]}
        data-background-image={ transformImg({ img: props.colorImage }) }
      ></div>
    )
  },
}
</script>

<style lang="less">
.product-card__color-item {
  width: 0.2667rem;
  height: 0.2667rem;
  border-radius: 50%;
  border: 0.0133rem solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
