<template>
  <div class="product-card__price-suggested">
    <template v-if="flipText">
      <span class="suggested-label">{{ labelText || '' }}:</span>
      <del class="suggested-price">{{ value && value.amountWithSymbol }}</del>
    </template>
    <template v-else>
      <del class="suggested-price">{{ value && value.amountWithSymbol }}</del>
      <span class="suggested-label">{{ labelText || '' }}</span>
    </template>
  </div>
</template>

<script>
/**
 * @file 合规价/划线价
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuggestedSalePrice',
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    flipText: {
      type: Boolean,
      default: false,
    }
  },
  setup() {},
})

</script>

<style lang="less" scoped>
.product-card__price-suggested {
  font-size: 0.293rem;
  font-weight: 400;
  color: #959595;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
