<script lang="jsx">
import { columnToCutImageSize } from '../../js/constant'

export default {
  name: 'ProductCardMaskLayer',
  functional: true,
  inject: {
    labelsFromKey: {
      default: ''
    },
    constantData: {
      from: 'constantData',
      default: () => ({})
    },
    item: {
      from: 'item',
      default: () => ({})
    },
    setAttrForAnalysis: {
      default: (() => {})
    },
  },
  props: {
    mainImg: {
      type: String,
      default: ''
    },
    column: {
      type: [String, Number],
      default: 2
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: ''
    },
    autoCropImage: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props = {}, injections = {} }) {
    const { mainImg, column, cropRate, autoCropImage } = props
    const { constantData, item, labelsFromKey, setAttrForAnalysis } = injections || {}
    const maskLayer = item?.[labelsFromKey]?.maskLayer ?? {}

    const {
      maskLayerSrc: maskImg,
      maskLayerWidth: maskWidth,
      maskLayerHeight: maskHeight,
      goodsRange: maskTagInfo,
    } = maskLayer ?? {}

    const isSquareSrc = () => {
      return mainImg.includes('_square')
    }

    const containerRatio = () => {
      const fixedRatio = cropRate ? cropRate : (autoCropImage ? '' : '3-4')

      if (fixedRatio === '3-4') {
        return 4 / 3
      }

      if (fixedRatio === '4-5') {
        return 5 / 4
      }

      if (fixedRatio === '5-6') {
        return 6 / 5
      }

      if (fixedRatio === '13-16') {
        return 16 / 13
      }

      if (fixedRatio === '14-17') {
        return 17 / 14
      }
      if (fixedRatio === '1-1') {
        return 1 / 1
      }

      // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
      if (isSquareSrc()) {
        // 1 : 1
        return 1 / 1
      }

      // 3 : 4
      return 4 / 3
    }

    if (
      maskImg &&
      maskWidth &&
      maskHeight &&
      containerRatio() === maskHeight / maskWidth
    ) {
      const imgDesignWidth = columnToCutImageSize[`${column}`] || 0

      if (maskTagInfo?.tagId) {
        setAttrForAnalysis((el) => {
          el.setAttribute('data-mask-layer', `show_mask_layer_${maskTagInfo?.tagId}`)
        })
      }
      return (
        <img
          src={constantData.LAZY_IMG_PIXEL}
          data-src={maskImg}
          data-design-width={imgDesignWidth}
          class="lazyload product-item__mask-layer"
        />
      )
    }
    return <div></div>
  }
}
</script>

<style lang="less" scoped>
.product-item {
  &__mask-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
}
</style>
