// body 锁定/解锁会触发scroll事件，避免影响scroll相关逻辑
let timer = 0
let endTime = 0 // 下次允许滚动的时间
let disabledScroll = false

export function isDisabledScroll() {
  return disabledScroll
}

// 取消禁止滚动
export function cancelDisabledScroll() {
  if (typeof window === 'undefined') return

  disabledScroll = false
  timer && clearTimeout(timer)
  timer = 0
  endTime = 0
}

export function setDisabledScroll (delay = 300) {
  if (typeof window === 'undefined') return
  const _endTime = Date.now() + delay
  if (endTime && _endTime < endTime) return

  endTime = _endTime
  disabledScroll = true
  timer && clearTimeout(timer)
  
  timer = setTimeout(() => {
    cancelDisabledScroll()
  }, delay)
}
