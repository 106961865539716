<template>
  <div
    class="sale-attr-container"
  >
    {{ label }}
  </div>
</template>
  
<script>
/**
 * @file 销售属性组件
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SaleAttrTag',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="less" scoped>
.sale-attr-container {
    padding: 2px 6px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 4px;
}
</style>
