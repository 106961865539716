
import { stringifyQueryString } from '@shein/common-function'

const MODULE_TYPE = {
  detail: 'recommend', // 商详
  list: 'ranking_list', // 列表榜单组件
  listLabel: 'ranking_list_label', // 列表榜单标签
  infoflow: 'infoflow',
  listFeedBack: 'ranking_feedback',
  brandinfoflow: 'brandinfoflow', // 品牌频道信息流
  similarRank: '', // 落地页底部相似榜单的src_module取当前落地页的src_module
  storeItem: 'store_item', // 店铺 item tab
  storeHome: 'storeHomePage', // 店铺 home tab
}

/**
* 获取榜单落地页链接
* @param {*} param0 
*/
export const getRankProductsUrl = ({
  carrierSubType = '',
  langPath,
  params,
  from = 'list',
}) => {
  const queryObj = Object.assign({}, params, {
    carrierSubType,
    src_module: MODULE_TYPE[from] || params.src_module,
  })
  if (queryObj.contentCarrierId) {
    return `${langPath}/ranking_list/page?${stringifyQueryString({
      queryObj,
    })}`
  }
  return ''
}
