var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.displayList?.length > 0)?_c('div',{staticClass:"product-labels-carousel",style:({
    '--label-height': `${_vm.height / 37.5}rem`,
    '--icon-size': `${_vm.iconSize / 37.5}rem`,
    '--text-color': _vm.color,
    '--text-width': `${_vm.textWidth / 37.5}rem`,
    '--text-top': `${_vm.offsetTop}px`
  })},[_c(_setup.SwiperSlide,{staticClass:"product-labels-wraper",attrs:{"autoplay":3000,"first-autoplay":1500,"transition-duration":300,"item-length":_setup.displayList?.length || 0,"touchable":false,"prevent-default":false,"loop":"","vertical":"","intersection-observer":""}},_vm._l((_setup.displayList),function(item,i){return _c(_setup.SwiperSlideItem,{key:i,attrs:{"index":i,"stop-propagation":""}},[_c('div',{staticClass:"product-labels-item"},[_c('span',{staticClass:"labels-item-icon"},[(item.icon.startsWith('sui_icon'))?_c(_setup.Icon,{attrs:{"name":item.icon,"color":_vm.iconColor,"size":`${_vm.iconSize / 37.5}rem`}}):_c('img',{attrs:{"src":item.icon}})],1),_vm._v(" "),_c('span',{staticClass:"labels-item-text"},[_vm._v(_vm._s(item.text))])])])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }