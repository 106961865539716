import { ref, watch, nextTick, onMounted } from 'vue'


/**
 * 商卡popover hook
 * @param {{
 *  popoverPlacemen: string, // popover默认位置  top | bottom | left | right
 * 
 * }} options 
 */
export const useCardPopover = (options) => {
  const goodsInfo = options.goodsInfo
  
  // 唯一id
  const id = ref('')
  // 是否激活pop弹窗
  const popoverActive = ref(false)
  // popover位置
  const  popoverPlacemen = ref(options?.popoverPlacemen || 'bottom')

  // 是否展示icon
  const iconCaution = ref(null)
  const showIconSuggested = ref(false)

  const popoverClick = (e) => {
    if (window?.deSuggestedSaleComponnet?.lock) return false
  
    if (!popoverActive.value) {
      const { clientY } = e
      const windowHieght = window.innerHeight
      const remainingHeight  = windowHieght - clientY
      
      // hack fix bug
      // 需要在打开找相似推荐时，去掉 body style 中的 top 如果有的话！避免该 top 影响 tooltip 的位置
      if (document.body.style.top) {
        document.body.style.top = ''
      }
  
      // 根据底部剩余宽度计算popover位置
      popoverPlacemen.value = remainingHeight > 100 ? 'bottom' : 'top'
      
      nextTick(() => {
        popoverActive.value = true
        window.deSuggestedSaleComponnet.popoverActiveId.value = id.value
      })
    } else {
      window.deSuggestedSaleComponnet.popoverActiveId.value = ''
    }
  }
  
  const popoverClickTouchstart = () => {
    if (window?.deSuggestedSaleComponnet?.lock) return false
  
    if (window.deSuggestedSaleComponnet?.popoverActiveId?.value) {
      window.deSuggestedSaleComponnet.popoverActiveId.value = ''
  
      window.deSuggestedSaleComponnet.lock = true
  
      setTimeout(() => {
        window.deSuggestedSaleComponnet.lock = false
      }, 500)
    }
  }

  onMounted(() => {
    const { goods_id, goods_sn } = goodsInfo
    id.value = `${goods_id}_${goods_sn}`
  
    const handleFun = () => {
      
      // 判断icon是否够位置展示
      // 没有被压缩变形，宽度高度一致，证明可以展示icon
      const { width, height } = iconCaution.value?.getBoundingClientRect()
      showIconSuggested.value = width >= height
      
      const touchHandler = (e) => {
        const { target } = e
  
        const popoverActiveId = window.deSuggestedSaleComponnet.popoverActiveId.value
        if (popoverActiveId && !target.className.includes('S-popover__main')) {
          const parentElement = target.parentElement.parentElement
          const popoverElement = parentElement.querySelector('.icon-suggested-caution')
          const popoverId = popoverElement?.getAttribute('data-id')
          if (popoverId === popoverActiveId) return false
      
          window.deSuggestedSaleComponnet.popoverActiveId.value = ''
          window.deSuggestedSaleComponnet.lock = true
  
          setTimeout(() => {
            window.deSuggestedSaleComponnet.lock = false
          }, 500)
        }
      }
  
      // 点击滑动除popover外任意部分需要隐藏popover
      if (!window.deSuggestedSaleComponnet) {
        window.deSuggestedSaleComponnet = {
          lock: false,
          drawerContainerDom: null,
          popoverActiveId: ref(''),
          touchEvent: window.document.addEventListener('touchstart', touchHandler)
        }
      }
  
      const { drawerContainerDom } = window.deSuggestedSaleComponnet
      
      // 是否在弹窗内 需要绑定弹窗的事件
      const _drawerContainerDom = iconCaution.value.closest('.S-drawer__container')
      if (_drawerContainerDom && drawerContainerDom !== _drawerContainerDom) {
        // 如果之前有事件，先移除
        if (drawerContainerDom) {
          drawerContainerDom.removeEventListener('touchstart', touchHandler)
        }
  
        window.deSuggestedSaleComponnet.drawerContainerDom = _drawerContainerDom
      }
  
      watch(
        window.deSuggestedSaleComponnet.popoverActiveId,
        (newVal) => {
          if ((!newVal || newVal !== id.value) && popoverActive.value) {
            popoverActive.value = false
          }
        }
      )
    }
  
    
    if (window.requestIdleCallback) {
      window.requestIdleCallback(handleFun)
    } else {
      setTimeout(handleFun, 50)
    }
  })
  
  
  return {
    id,
    popoverActive,
    popoverPlacemen,
    iconCaution,
    showIconSuggested,
    popoverClick,
    popoverClickTouchstart
  }
}