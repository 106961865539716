<script lang="jsx">
import { labelTypeMap } from '../../js/constant.js'

// comp
// import ProductCardSheinClubPrice from './ProductCardSheinClubPrice.vue'
import ProductCardPriceVipPrice from './ProductCardPriceVipPrice.vue'
import ProductCardNormalPrice from './ProductCardNormalPrice.vue'

import DiscountLabel from '../UI/DiscountLabel.vue'
import PriceDiscountIntro from './PriceDiscountIntro.vue'
import EstimatedNewStyle from './EstimatedNewStyle.vue'

export default {
  name: 'ProductCardPrices',
  functional: true,
  inject: ['labelsFromKey', 'multiColumn', 'config', 'constantData', 'setAttrForAnalysis', 'item', 'finalPriceAndDiscountInfo'],
  props: {
    // 会员专属折扣价
    exclusivePromotionPrice: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    showBestDeal: {
      type: Boolean,
      default: false
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    // ccc返回的最终腰带
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    },
    // 调试开启，最终显示的价格中文
    supportVueDebug: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, data, injections, slots }) {
    const { labelsFromKey, multiColumn, config, constantData, setAttrForAnalysis, item, finalPriceAndDiscountInfo } = injections
    
    const { showBestDeal, exclusivePromotionPrice, showCamelPrice, beltLabel, supportVueDebug } = props || {}

    const { salePrice, retailPrice } = item
    
    const { color, finalDiscount, finalPrice, priceRight, priceBottom, priceLabelText, finalPriceType, priceStateCollection, suggestedSalePriceData } = finalPriceAndDiscountInfo || {}

    const { hideTargetBySuggestedSalePrice } = suggestedSalePriceData?.config || {}
    const { GB_cssRight, IS_RW } = constantData || {}

    // 价格右侧的标签。 优先级：小号付费会员折扣标签 > 普通折扣标签 > 销量标签
    // warn: 标签有个宽度计算过程，当宽度不够时隐藏, 计算逻辑在Item.vue中
    const discountEl = (() => {
      const isSpecialDeSuggested = finalPriceAndDiscountInfo?.isSpecialDeSuggested
      const showDiscountLabel = !isSpecialDeSuggested && config.showDiscountLabelAfterPrice && finalDiscount?.show && !priceStateCollection.hideDiscountLabel

      const { salesLabels = [] } = item?.[labelsFromKey] ?? {}
      const hasSalesLabel = !!Object.keys(salesLabels || {})?.length
      const labelCarouselConfig = config?.showLabelCarousel || false
      const showSalesLabel = labelCarouselConfig ? false : config.hasOwnProperty('showSalesLabel') ?
        (config.showSalesLabel && hasSalesLabel) :
        hasSalesLabel

      if (showDiscountLabel) { // 普通折扣标签
        return (
          <DiscountLabel
            type='discount'
            discount-info={finalDiscount}
            color={color}
          />
        )
      } else if (showSalesLabel) { // 销量标签
        // 埋点
        setAttrForAnalysis((el) => {
          const anaValue = `show_sales_label_${labelTypeMap.get(salesLabels.textType)}_${salesLabels.labelId}_${salesLabels.labelLang?.replace(/\s+/g, '_')}`
          el.setAttribute('data-sales-label', salesLabels?.appTraceInfo || anaValue)
        })

        const styles = { color: salesLabels?.fontColor || '', backgroundColor: salesLabels?.backgroundColor || '' }
        return (
          <div 
            class={[
              'product-card__sales-label',
              salesLabels.icon ? 'sales-label-hot' : ''
            ]}
            style={styles}
          >
            {
              salesLabels.icon 
                ? <img class="sales-label__icon" src={salesLabels.icon} />
                : null
            }
            <span class="sales-label__text">{salesLabels.labelLang}</span>
          </div>
        )
      } 
      return null
    })()

    // 价格上方El
    const priceTopEl = (() => {
      // 新型闪购
      const flashTypeText = finalPriceType === 'newFlashPrice' && finalPrice?.flashTypeText

      const flashTypeTextEl = () => {
        if (!flashTypeText) return null
        return (
          <div class="normal-price__flash-label">{flashTypeText}</div> 
        )
      }
      if (config.hasOwnProperty('showNewFlashNewLabel') && config.showNewFlashNewLabel) {
        return flashTypeTextEl()
      } else {
        return flashTypeTextEl()
      }

    })()


    const showEstimatedNewStyle = config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice && !priceStateCollection.finalPriceIsABPrice
    const estimatePriceTagEl = (() => {

      // 仅会员到手价（注意： 需要满足门槛），走新的到手价
      if(showEstimatedNewStyle) {
        return <EstimatedNewStyle />
      }

      return null
    })()

    // 价格下面的标签
    const belowPriceLabelEl = (() => {
      if (hideTargetBySuggestedSalePrice || estimatePriceTagEl) return null // 不展示价格的标签

      // 价格优势说明标签
      const pdiEl = () => {
        const { historicalPrice, followPrice } = item?.[labelsFromKey] ?? {}

        return config.showPriceDiscountDesc ? <PriceDiscountIntro
          setAttrForAnalysis={setAttrForAnalysis}
          config={config}
          historicalPrice={historicalPrice}
          followPrice={followPrice}
          salePrice={salePrice}
          retailPrice={retailPrice} /> : null
      }

      const priceLabelEl = () => {
        if (!priceLabelText) return null
        return (
          <div class={['product-card__text-label', color]}>
            {priceLabelText}
          </div>
        )
      }

      return priceLabelEl() || pdiEl() || slots().belowPrice
    })()

    // 合规价，价格下方文案
    const priceBottomEl = (() => {
      if (!priceBottom.show) return null
      return (
        <SuggestedSalePrice
          flipText={priceBottom.flipText}
          value={priceBottom.priceData}
          labelText={priceBottom.labelText}
        />
      )
    })()

    let priceClassName = {
      'product-card__prices': true,
      'camel-price': showCamelPrice
    }
    if (priceRight.show) {
      priceClassName = Object.assign(priceClassName, {
        'price-height-40': true,
      })
    } else {
      priceClassName = Object.assign(priceClassName, {
        'price-overflow-hidden': true,
        // 一行二图 无大小数字 21
        'price-height-21': !multiColumn && !showCamelPrice && !config?.showNewStyleEstimated,
        // 大小数字统一为20
        'price-height-20': showCamelPrice || config?.dynamicCamelPrice,
        // 一行多图 无大小数字 15
        'price-height-15': multiColumn && !showCamelPrice,
        // 新到手价样式下补充(解决：售价位置不会展示驼峰，且价格右侧出现销量标签这类信息（一行展示不完被换行了，底部的价格说明没挡住这个销量标签信息）) 一行二图
        'price-height-17': config?.showNewStyleEstimated && !multiColumn && !showCamelPrice,
        
      })
    }
    
    const PriceV2LabelPosition = config?.PriceV2LabelPosition || 'priceBottom'

    const showProductCardPriceVipPrice = exclusivePromotionPrice?.amountWithSymbol && !config.hideS3VipPrice && !config.showS3VipPriceOnSale && !suggestedSalePriceData?.type && showEstimatedNewStyle

    return (
      <div
        class={[
          data.class,
          data.staticClass,
        ]} 
      >
        {/* 价格上方 */}
        {
          priceTopEl
        }

        {/* 价格 */}
        <div 
          class={priceClassName}
        >
          {
            <div class="product-card__prices-info">
              {/* 普通价格（售卖价和零售价） */}
              {
                config.hideNormalPrices ? null : (
                  <ProductCardNormalPrice
                    multi-column={multiColumn}
                    show-camel-price={showCamelPrice}
                    show-best-deal={showBestDeal}
                    belt-label={beltLabel}
                    support-vue-debug={supportVueDebug}
                  />
                )
              }
            </div>
          }
          
          {/* 到手价标签和付费会员标签 */}
          { PriceV2LabelPosition === 'priceRight' ? belowPriceLabelEl : null }

          { discountEl }
          
        </div>

        {/* 价格下方 */}
        {/* vip会员专属价格 */}
        {/* 合规场景不展示 */}
        {
          showProductCardPriceVipPrice ? (
            <ProductCardPriceVipPrice
              is-rw={IS_RW}
              amount-with-symbol={exclusivePromotionPrice.amountWithSymbol}
              css-right={GB_cssRight}
            />
          ) : null
        }
      
        {/* 到手价标签和付费会员标签 */}
        { estimatePriceTagEl }
        { PriceV2LabelPosition === 'priceBottom' ? belowPriceLabelEl : null }

        {/* 合规价，价格下方文案 */}
        {
          priceBottomEl
        }
      </div>
    )
  }
}
</script>

<style lang="less">
.normal-price__flash-label {
  .font-dpr(20px);
  color: @sui_color_discount;
  line-height: 0.32rem;
}
.product-card__newflash-label {
  .font-dpr(20px);
  display: inline-block;
  height: 0.4267rem;
  line-height: 0.4267rem;
  white-space: nowrap;
  padding: 0 0.1067rem;
  margin-top: 0.1067rem;
  color: @sui_color_promo_dark;
  background: @sui_color_promo_bg;
}

.product-card {
  &__prices {
    .flexbox();
    align-items: baseline;
    flex-wrap: wrap;
    text-align: left;
    
    &.price-height-15 {
      max-height: .3733rem;
    }
    &.price-height-20 {
      max-height: .5333rem;
    }
    &.price-height-21 {
      max-height: 21px;
    }
    &.price-height-40 {
      max-height: 1.06rem;
    }
    &.price-height-17 {
      max-height: 0.4533rem;
    }
    .product-card__discount {
      margin-left: .107rem;
      align-self: center;
    }

    .product-card__text-label {
      margin-left: .107rem;
    }

    &.camel-price {
      .prices-info{
        align-items: baseline;
        .price-info_s3vip-icon {
          position: relative;
          top: .026rem;
        }
      }
      .product-card__sales-label:not(.sales-label-hot) {
          margin-top: .026rem;
        }
    }

  }

  .price-overflow-hidden {
    overflow: hidden; // checkout button 过长的时候会隐藏 销量标签
  }

  &__prices-info {
    flex: 0 0 auto;
    max-width: 100%;

    .flexbox();
    .align-center();
    .space-between();
    position: relative;
    flex-wrap: wrap;
    
    .sui_icon_more_20px { /* stylelint-disable-line */
      position: absolute;
      top: 0;
      right: 0;
      height: 0.4533rem;
      line-height: 0.4533rem;
      color: #959595;
    }
  }
  &__sales-label {
    align-self: center;
    .flexbox();
    align-items: center;
    margin-left: 0.0533rem;
    font-size: 0.2933rem;
    color: @sui_color_gray_dark3;
    line-height: 0.3467rem;
    white-space: nowrap;
    
    .sales-label {
      &__icon {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.053rem;
      }
      &__text {
        height: 0.32rem;
      }
    }
  }
  &__sheinclub-discount_small {
    margin-left: 0.1067rem;
  }
  &__newflash-label {
    margin-top: 0.0533rem;
  }
  &__text-label {
    font-size: 0.2667rem;
    line-height: 0.32rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    
    // origin橙色促销色   brown棕色会员色
    &.brown {
      color: @sui_color_club_rosegold_dark1;  
    }
    &.origin {
      color: @sui_color_discount;
    }
    &.darkGold {
      color: #806208;
    }

    &_new-discount {
      border-radius: 2px;
      background-color: var(--promo_BG, #FFF1ED);
      color: @sui_color_discount;
    }
  }

}
</style>
