<script lang="jsx">
import Vue from 'vue'
import { Checkbox } from '@shein/sui-mobile'
import DiscountLabel from '../UI/DiscountLabel.vue'

Vue.use(Checkbox)
export default {
  name: 'ProductCardImgLeftTop',
  functional: true,
  inject: {
    multiColumn: {
      from: 'multiColumn',
      default: false
    },
    constantData: {
      default: () => ({})
    },
    config: {
      from: 'config',
      default: () => ({})
    },
    finalPriceAndDiscountInfo: {
      from: 'finalPriceAndDiscountInfo',
      default: () => ({})
    }
  },
  props: {
    selectStatus: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    selectPosition: {
      type: String,
      default: ''
    },
    showDiscountLabel: {
      type: Boolean,
      default: false,
    }
  },
  render(h, { props, injections }) {
    const { multiColumn, constantData, config, finalPriceAndDiscountInfo } = injections || {}

    const { showDiscountLabel, showSelect, selectPosition } = props

    const { IS_RW } = constantData || {}

    const { color, finalDiscount, isSpecialDeSuggested } = finalPriceAndDiscountInfo || {}

    // 德国合规多行场景不展示折扣标签
    const isDeMultiColumnDiscount = isSpecialDeSuggested && multiColumn

    const showImgLeftCheckbox = showSelect && selectPosition === 'imgLeft'

    const checkedBoxStyle = config?.style?.checkedBoxStyle ?? ''


    if (showImgLeftCheckbox) {
      return (
        <div class="product-card__img-topleft">
          <s-checkbox
            value={props.selectStatus}
            class={['product-card__select', checkedBoxStyle]}
            normal-style="black02-black"
            margin-r="0"
          />
        </div>
      )
    } else if (showDiscountLabel && !isDeMultiColumnDiscount) {
      // 折扣标签
      return (
        <DiscountLabel
          class="product-card__topleft-discount"
          color={color}
          type='discount'
          discount-info={finalDiscount}
          isRW={IS_RW}
        />
      )
    } else {
      return null
    }
  },
}
</script>

<style lang="less">
.product-card__topleft-discount {
  position: absolute;
  left: 0 /* rtl: ignore */;
  top: 0;
  z-index: @zindex-hack;
  /*rw:begin*/
  top: 0;
}

.product-card__img-topleft {
  .product-card {
    &__select {
      &.S-checkbox {
        position: absolute;
        top: 0.16rem;
        left: 0.16rem;
        z-index: @zindex-select;
      }
    }
  }
}
</style>
