<script lang="jsx">
import ProductICardColorItem from './ProductICardColorItem.vue'

export default {
  name: 'ProductCardColorSet',
  functional: true,
  props: {
    // 所有色块图片地址集合: object[]
    relatedColor: {
      type: Array,
      required: true,
    },
    // 色块展示的方向: horizontal 或者 vertical
    direction: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data }) {
    return (
      <div
        class={[
          'product-card__color-set',
          data.class,
          data.staticClass,
          {
            'product-card__color-set_horizontal': props.direction === 'horizontal',
            'product-card__color-set_vertical': props.direction === 'vertical',
          },
        ]}
        style={[
          data.style,
          data.staticStyle
        ]}
      >
        {props.relatedColor.slice(0, 3).map((color, index) => (
          <ProductICardColorItem
            class="product-card__color-item"
            key={index}
            index={index}
            color-image={color.goods_color_image}
          />
        ))}
        {props.relatedColor.length > 3 ? <div class="product-card__color-count">{ props.relatedColor.length }</div> : null}
      </div>
    )
  },
}
</script>

<style lang="less">
.product-card__color-set {
  border: 0.5px solid rgba(255, 255, 255, 0.30);
  border-radius: 5000px;
  background: rgba(0, 0, 0, 0.30);
  display: flex;
  margin-bottom: 0.107rem;
  margin-right: 0.107rem;
  margin-left: auto;
}
.product-card__color-set_horizontal {
  padding: 0.04rem 0.08rem;
  align-items: center;
  .product-card__color-item {
    &:not(:first-child) {
      margin-left: -0.107rem;
    }
    position: relative;
  }
  .product-card__color-count {
    margin-left: 0.027rem;
  }
}
.product-card__color-set_vertical {
  padding: 0.08rem 0.027rem;
  flex-direction: column;
  align-items: center;
  gap: 0.053rem;
  .product-card__color-count {
    line-height: 0.293rem;
  }
}
.product-card__color-count {
  font-size: 0.24rem;
  line-height: 0;
  color: #fff;
}
</style>
