import VisitorLabel from './VisitorLabel.vue'

const getVisitorLabel = ({ item }) => item.pretreatInfo?.numberOfVisitorsLabel?.labelLang || ''

const getVisitorText = ({ item }) => {
  return getVisitorLabel({ item })
}

const hasVisitorLabel = ({ item }) => {
  return !!getVisitorLabel({ item })
}

const setVisitorLabelAna = ({ el, item }) => {
  const val = getVisitorLabel({ item })
  if (val && item.pretreatInfo?.stockLeft) {
    el.setAttribute('data-visitor-label', item.pretreatInfo?.numberOfVisitorsLabel.appTraceInfo)
  }
}

export {
  VisitorLabel,
  setVisitorLabelAna,
  getVisitorText,
  hasVisitorLabel,
}
