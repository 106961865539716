
import ProductLabelCarousel from 'public/src/pages/product_list_v2/components/common/ProductLabelCarousel/index.vue'
import { getEfficientLabels } from 'public/src/pages/product_list_v2/components/common/ProductLabelCarousel/logic.js'

const sortLabels = [
  (item) => {
    const text = item?.pretreatInfo?.stockLeft
    if (!text) return ''
    return {
      icon: 'warn',
      key: '-1',
      text
    }
  },
  '3526',
  '3527',
  '1661',
  '207',
  '3460',
  '3457',
  '3726',
  '3454'
]

const getLabelCarousel = ({ item }) => {
  const { efficientLabels = [] } = getEfficientLabels(item, sortLabels)
  const isSoldout = item.is_on_sale == 0 || item.stock == 0 || item.sale_percent == 1
  return efficientLabels.length > 0 && !isSoldout
}

const setLabelCarouselAna = ({ el, item }) => {
  // bff 与 web中间层取值不同
  if (item.bffGoodsInfo) {
    const popularLabelList = item?.pretreatInfo?.popularLabelList || []

    if (popularLabelList.length) {
      el.setAttribute('data-label-carousel', popularLabelList.map(v => v.appTraceInfo).join('|'))
    }
  } else {
    const { saLabels = [] } = getEfficientLabels(item, sortLabels)
    if (saLabels.length) {
      el.setAttribute('data-label-carousel', saLabels.join('|'))
    }
  }
}

export {
  ProductLabelCarousel,
  getLabelCarousel,
  setLabelCarouselAna
}
