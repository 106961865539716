<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'

export default {
  name: 'ProductCardBuyBoxEntrance',
  props: {
    count: { type: Number, default: 0 },
    price: { type: String, default: '' },
    goodsId: { type: [String, Number], default: '' },
    language: { type: Object, default: () => ({}) },
    constantData: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      num: 0,
      countData: { all: '', count: '', otherSellers: '', className: '' },
      priceData: { otherSellers: '', from: '', price: '', className: '' }
    }
  },
  watch: {
    'price': {
      handler (val) {
        this.analysisExpose()
        this.setCountData()
        this.setPriceData()
        if (!val) return
        this.computedTextWidth('refBuyBoxPrice')
      },
      immediate: true,
    },
  },
  methods: {
    analysisExpose() {
      const { price, goodsId } = this
      daEventCenter.triggerNotice({
        daId: '2-3-46',
        extraData: {
          goods_id: goodsId,
          expose_times: price ? 2 : 1,
          buy_box_style: price ? 1 : 2
        }
      })
    },
    analysisClick() {
      const { price, goodsId } = this
      daEventCenter.triggerNotice({
        daId: '2-3-47',
        extraData: {
          goods_id: goodsId,
          expose_times: price ? 2 : 1,
          buy_box_style: price ? 1 : 2
        }
      })
    },
    onClick() {
      setDisabledScroll()
      this.analysisClick()
      this.$emit('openBuyBoxDrawer')
    },
    async switchCountToPrice() {
      await this.$nextTick()
      this.$refs.refBuyBoxCount?.classList?.add('to-fade__out-end')
      this.$refs.refBuyBoxPrice?.classList?.add('to-fade__in-end')
    },
    setCountData () {
      const { count, price, language } = this
      const languageText = language?.SHEIN_KEY_PWA_30174 || ''
      const split = languageText.split('{0}')
      const all = split?.[0]?.trim() || ''
      const otherSellers = split?.[1]?.trim() || ''
      const className = price ? 'to-fade to-fade__out-start' : ''
      this.countData = { all, otherSellers, count, className }
    },
    setPriceData () {
      const { price, language } = this
      // 测试文案长度用
      // let otherSellers = language?.SHEIN_KEY_PWA_30388 || ''
      // if (this.goodsId == 13726843) {
      //   otherSellers = new Array(3).fill(otherSellers).join(' ')
      // } else if (this.goodsId == 18055660) {
      //   otherSellers = new Array(6).fill(otherSellers).join(' ')
      // }
      const otherSellers = language?.SHEIN_KEY_PWA_30388 || ''
      const from = language?.SHEIN_KEY_PWA_31143 || ''
      const className = price ? 'to-fade to-fade__in-start' : ''
      this.priceData = { otherSellers, from, price, className }
    },
    // 计算文案是否超长
    async computedTextWidth () {
      await this.$nextTick()
      const dom = this.$refs.refBuyBoxPrice
      const domNum = dom?.querySelector('.buy-box__num')
      if (typeof window === 'undefined' || !dom || !domNum) return
      const rect1 = dom.getBoundingClientRect()
      const rect2 = domNum.getBoundingClientRect()
      const diff1 = rect1.bottom - rect2.bottom
      const diff2 = rect1.right - rect2.right
      if (this.num >= 20 || (diff1 >= -2 && diff2 >= -2)) {
        this.switchCountToPrice()
        return
      }

      this.num += 1
      const { otherSellers } = this.priceData
      this.priceData.otherSellers = otherSellers.substring(0, otherSellers.length - 8) + '...'

      requestAnimationFrame(() => {
        this.computedTextWidth()
      })
    },
  },
  render () {
    const renderCount = ({ all, otherSellers, count, className }) => {
      return (
        <span class="buy-box__label-container">
          <span
            class={className}
            ref="refBuyBoxCount"
          >
            {all}
            <span class="buy-box__num">{count}</span>
            {otherSellers}
          </span>
        </span>
      )
    }
    const renderPrice = ({ otherSellers, from, price, className }) => {
      if (!price) return null

      return (
        <span class="buy-box__label-container">
          <span 
            class={className}
            ref="refBuyBoxPrice"
          >
            {otherSellers}&nbsp;{from}&nbsp;
            <span class="buy-box__num">{price}</span>
          </span>
        </span>
      )
    }
    const styles = { transform: this.constantData?.GB_cssRight ? 'rotateY(180deg)' : '' }
    return (
      <div 
        class="buy-box__container"
        v-on:click_stop={this.onClick}
      >
        <div class="buy-box__label">
          {renderCount(this.countData)}
          {renderPrice(this.priceData)}
        </div>

        <div class="buy-box__icon" style={styles}>
          <img
            src="https://img.ltwebstatic.com/images3_ccc/2024/07/30/81/172231019004f6d48690f1803a4ddde09ef86ad545.png"
          />
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="less">
@buyBoxHeight: 22px;

.buy-box {
  &__container {
    width: 100%;
    display: flex;
    height: @buyBoxHeight;
    border: 0.01333rem solid transparent;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    border-radius: 3px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(270deg, #FFE8D8 0%, #FFF7E7 100.32%), linear-gradient(to right, #FFEBC2, #FECEAC);
  }

  &__label {
    flex: 1;
    height: @buyBoxHeight;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-right: 4px;
    margin-left: 4px;
  }

  &__label-container {
    display: flex;
    height: @buyBoxHeight;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    >span {
      max-height: @buyBoxHeight;
      color: @sui_color_club_rosegold_dark1;
      font-size: 10px;
      line-height: 1;
      font-style: normal;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      >span {
        max-width: 100%;
        display: inline-block;
      }
    }
  }

  &__num{
    margin: 0 2px;
    color: @sui_color_discount;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @buyBoxHeight;

    img {
      height: 100%;
    }
  }
}

// 处理 count -> price 过度效果
.to-fade{
  transition: opacity 200ms;

  &__out-start {
    opacity: 1;
  }
  &__out-end {
    opacity: 0;
  }

  &__in-start {
    opacity: 0;
  }
  &__in-end {
    opacity: 1;
  }
}
</style>
