
<script lang="jsx">
export default {
  name: 'ProductCardSellingPropositionSlideItem',
  functional: true,
  components: {},
  render(h, { data, props, slots }) {
    const { itemData, showAverage = false, showIcon = false } = props

    if (itemData.type === 'star') {
      // 星级评论
      const { comment_rank_average = '', comment_num_str = '', star_html = '' } = itemData.starData ?? {}
      return comment_rank_average && comment_num_str ? (
        <div class="product-card__selling-proposition-star">
          <ul class="star-icon-list" domPropsInnerHTML={star_html}></ul>
          {
            showAverage ? <p class="average-text">{comment_rank_average}</p> : null
          }
          <p class="start-text">({comment_num_str})</p>
        </div>
      ) : ''
    }
    
    if (itemData.type === 'text') {
      const { text, fontStyle = '', icon = '', suiIcon = '' } = itemData.textData ?? {}
      const textClass = [
        'product-card__selling-proposition-text',
        {
          'font-gray': fontStyle === 'gray',
          'font-golden': fontStyle === 'golden',
        }
      ]

      const style = [itemData.style ?? {}, data.style, data.staticStyle]

      // 文字前的图标
      let beforeTextIcon = null
      if (showIcon) {
        beforeTextIcon = icon
          ? <img width={'12px'} height={'12px'} src={icon} class="selling-proposition__text-icon" /> 
          : suiIcon
            ? <i class={{ 'sh_m_sui_icon': true, [suiIcon]: suiIcon }}></i>
            : null
      }

      return (
        <p class={textClass} style={style}>
          { beforeTextIcon }
          { text }
        </p>
      )
    }

    if (slots().default && itemData.type === 'slot') {
      return slots().default
    } 

    return ''
  }
}

</script>


<style lang="less">
// 星级评论 start
.product-card__selling-proposition-star {
  .flexbox();
  .align-center();
  
  .suiiconfont, .suiiconfont-critical {
    color: @sui_color_honor;
  }

  .star-icon-list {
    height: .373rem;
    .flexbox();
    .align-center();
    margin-right: var(--single-star-margin-right, 0px);

    /* stylelint-disable-next-line selector-max-specificity */
    .suiiconfont[class*="_15px"] {
      font-size: .373rem;
    }
  }

  .average-text {
    height: 1em;
    line-height: 1em;
    font-size: .266rem;
    color: #767676;
    font-weight: 400;
    margin-right: 1px;
  }

  .start-text {
    height: 1em;
    line-height: 1em;
    font-size: .266rem;

    color: var(--sui-color-gray-dark-2, #666);
    font-weight: var(--single-star-font-weight);
  }
}
// 星级评论 end

// 文字 start
.product-card__selling-proposition-text {
  text-align: left;
  .line-camp(1);
  max-width: 100%;
  height: .373rem;
  line-height: .373rem;
  font-size: .293rem;

  &.font-gray {
    color: var(--sui-color-gray-dark-3, #767676);
  }
  &.font-golden {
    color: #CC6E00;
  }

  .selling-proposition__text-icon {
    width: 0.32rem;
    height: 0.32rem;
    object-fit: contain;
    margin-right: 2px;
  }

  .sh_m_sui_icon {
    margin-right: 0.053rem;
    vertical-align: -0.053rem;
  }
}
// 文字 end
</style>
